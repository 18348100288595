import React from 'react';
import { Router, Redirect } from '@gatsbyjs/reach-router';

import PrivateRoute from '../navigator/PrivateRoute';

import { ThemeProvider } from 'styled-components';
import theme from '../styles/colors';

import { routes, routeswithoutNavbar } from '../navigator/routes';
import AppLayout, { validateToken } from '../components/AppLayout';
import { Body } from '../styles/typography';
import ViewLayout from '../components/ViewLayout';

const Placeholder = () => (
  <ViewLayout>
    <Body
      style={{
        padding: 24,
        width: '100%',
        height: '100%',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        boxSizing: 'border-box',
        color: `rgb(${theme.colors.neutral200})`,
        background: `rgb(${theme.colors.offWhite})`,
      }}
    >
      🚧 This is under construction 🚧
    </Body>
  </ViewLayout>
);

const App = () => (
  <ThemeProvider theme={theme}>
    <AppLayout>
      <Router onChange={validateToken}>
        <Redirect from="/app" to={routes.recent.path} noThrow />

        {
          // Main Views
        }
        <PrivateRoute
          path={routes.recent.path}
          component={routes.recent.component}
          subNav={true}
        />

        {process.env.GATSBY_EDIT === 'TRUE' ? (
          <PrivateRoute
            path={routes.edit.path}
            component={routes.edit.component}
            subNav={true}
          />
        ) : null}

        {process.env.GATSBY_PROMPTS === 'TRUE' ? (
          <PrivateRoute
            path={routes.prompts.path}
            component={routes.prompts.component}
          />
        ) : null}

        <PrivateRoute
          path={`${routes.recent.path}/:cardType`}
          component={routes.recent.component}
          subNav={true}
        />
        {process.env.GATSBY_NOTIFICATIONS === 'TRUE' ? (
          <PrivateRoute
            path={routes.notifications.path}
            component={routes.notifications.component}
          />
        ) : null}

        <PrivateRoute
          path={routes.settings.path}
          component={routes.settings.component}
        />
        <PrivateRoute
          path={routeswithoutNavbar.expandMemory.path}
          component={routeswithoutNavbar.expandMemory.component}
        />
        <PrivateRoute
          path={`${routeswithoutNavbar.expandMemory.path}/:id`}
          component={routeswithoutNavbar.expandMemory.component}
          subNav={false}
        />
        <PrivateRoute
          path={`${routeswithoutNavbar.newMemory.path}/:id`}
          component={routeswithoutNavbar.newMemory.component}
          subNav={false}
        />
        <PrivateRoute
          path={routeswithoutNavbar.gallery.path}
          component={routeswithoutNavbar.gallery.component}
          subNav={false}
        />
        <PrivateRoute
          path={routeswithoutNavbar.onboarding.path}
          component={routeswithoutNavbar.onboarding.component}
          subNav={false}
        />
        {/* <PrivateRoute
          path={`${routeswithoutNavbar.onboarding.path}/:pageNum`}
          component={routeswithoutNavbar.onboarding.component}
          subNav={false}
        /> */}

        {
          // Catch-all for unbuilt Views
        }
        <PrivateRoute default component={Placeholder} />
        {
          // Auth pages
        }
      </Router>
    </AppLayout>
  </ThemeProvider>
);

export default App;
